import * as React from "react";

function queryStringParse(search) {
  const params = {}
  if (search.match(/^\?.*$/)) {
    const paramStr = search.replace(/^\?(.*)$/, "$1")
    const paramArr = paramStr.split("&")
    for (let i = paramArr.length - 1; i >= 0; i--) {
      const arr = paramArr[i].split('=')
      if (arr.length == 2) {
        params[arr[0]] = arr[1];
      }
    }
  }
  return params;
}

const Test = ({location}) => {
  const search = location.search
  return (<>

  </>);
}

export default Test
